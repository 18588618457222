// src/pages/TakeSubscription.js
import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PageStyles.css';

const TakeSubscription = () => {
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleTakeSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios.post('https://pay.kraca.ru/api', {
        method: 'take',
        id: userId,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      setSuccess('Подписка успешно отозвана');
    } catch (error) {
      setError('Ошибка при выполнении запроса');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-4">
      <div className="back-button-container">
        <Button variant="secondary" onClick={() => navigate('/admin')}>Вернуться назад</Button>
      </div>
      <div className="d-flex flex-column align-items-center">
        <h1>Забрать подписку</h1>
        <Form onSubmit={handleTakeSubscription}>
          <Form.Group controlId="take-subscription">
            <Form.Label>Введите ID пользователя</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите id"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3" disabled={loading}>
            {loading ? 'Отзыв...' : 'Отозвать'}
          </Button>
        </Form>
        {error && <p className="text-danger mt-3">{error}</p>}
        {success && <p className="text-success mt-3">{success}</p>}
      </div>
    </Container>
  );
};

export default TakeSubscription;
