// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Home2 from './components/Home2';
import Search from './components/Search.js';
import Search2 from './components/Search2.js';
import GiveSubscription from './components/GiveSubscription.js';
import GiveSubscription2 from './components/GiveSubscription2.js';
import TakeSubscription from './components/TakeSubscription';
import TakeSubscription2 from './components/TakeSubscription2';
import Ban from './components/Ban.js';
import Ban2 from './components/Ban2.js';
import Unban from './components/Unban';
import Unban2 from './components/Unban2';
import Newsletter from './components/Newsletter';
import Newsletter2 from './components/Newsletter2';
import Givetoken from './components/Givetoken';
import Givetoken2 from './components/Givetoken2';
import Taketoken from './components/Taketoken.js';
import Taketoken2 from './components/Taketoken2.js';
import Messages from './components/Messages.js';
import Dialog from './components/Dialog';
import Payment from './components/Payment.js';
import Partner from './components/Partner.js';
import Partner2 from './components/Partner2.js';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Payment />} />
        <Route path="/admin" element={<Home />} />
        <Route path="/admin2" element={<Home2 />} />
        <Route path="/admin/search" element={<Search />} />
        <Route path="/admin2/search" element={<Search2 />} />
        <Route path="/admin/give-subscription" element={<GiveSubscription />} />
        <Route path="/admin2/give-subscription" element={<GiveSubscription2 />} />
        <Route path="/admin/take-subscription" element={<TakeSubscription />} />
        <Route path="/admin2/take-subscription" element={<TakeSubscription2 />} />
        <Route path="/admin/ban" element={<Ban />} />
        <Route path="/admin2/ban" element={<Ban2 />} />
        <Route path="/admin/message" element={<Messages />} />
        <Route path="/admin/unban" element={<Unban />} />
        <Route path="/admin2/unban" element={<Unban2 />} />
        <Route path="/admin/givetoken" element={<Givetoken />} />
        <Route path="/admin2/givetoken" element={<Givetoken2 />} />
        <Route path="/admin/partner" element={<Partner />} />
        <Route path="/admin2/partner" element={<Partner2 />} />
        <Route path="/admin/taketoken" element={<Taketoken />} />
        <Route path="/admin2/taketoken" element={<Taketoken2 />} />
        <Route path="/admin/newsletter" element={<Newsletter />} />
        <Route path="/admin2/newsletter" element={<Newsletter2 />} />
        <Route path="/admin/dialog/:id" element={<Dialog />} />
      </Routes>
    </Router>
  );
};

export default App;
