import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PageStyles.css';

const Givetoken2 = () => {
  const [userId, setUserId] = useState('');
  const [token, setToken] = useState(3000);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleGiveSubscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios.post('https://pay.kraca.ru/apivk', {
        method: 'givetoken',
        id: userId,
        token: token
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      setSuccess('token успешно выдан');
    } catch (error) {
      setError('Ошибка при выполнении запроса');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-4">
      <div className="back-button-container">
        <Button variant="secondary" onClick={() => navigate('/admin2')}>Вернуться назад</Button>
      </div>
      <div className="d-flex flex-column align-items-center">
        <h1>Выдать token</h1>
        <Form onSubmit={handleGiveSubscription}>
          <Form.Group controlId="give-subscription">
            <Form.Label>Введите ID пользователя</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите id"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="give-subscription">
            <Form.Label>Введите кол во </Form.Label>
            <Form.Control
              type="Number"
              min={0}
              max={100000}
              placeholder="Введите кол во "
              value={token}
              onChange={(e) => setToken(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3" disabled={loading}>
            {loading ? 'Выдача...' : 'Выдать'}
          </Button>
        </Form>
        {error && <p className="text-danger mt-3">{error}</p>}
        {success && <p className="text-success mt-3">{success}</p>}
      </div>
    </Container>
  );
};

export default Givetoken2;
