import React, { useState } from 'react';
import { Button, Container, Form, Card, ListGroup, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PageStyles.css';

const Partner2 = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState(null);
  const [activePartners, setActivePartners] = useState([]);
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    if(!e === false) {
        if (!Number(e)) e.preventDefault();
    }
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('https://pay.kraca.ru/apivk', {
        method: 'partner',
        id: Number(e) ? e : searchTerm,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data.partner !== true) {
        setUserData(response.data);
        setError('Пользователь не зарегистрирован как партнер');
      } else {
        setUserData(response.data);
      }
    } catch (error) {
      setError('Ошибка при выполнении запроса');
    } finally {
      setLoading(false);
    }
  };

  const handleRegisterPartner = async () => {
    try {
      await axios.post('https://pay.kraca.ru/apivk', {
        method: 'registerPartner',
        id: userData.id,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      setUserData({ ...userData, partner: true });
      setError(null);
    } catch (error) {
      setError('Ошибка при регистрации партнера');
    }
  };

  const handleUpdateBalance = async (amount, method) => {
    try {
      await axios.post('https://pay.kraca.ru/apivk', {
        method: method,
        id: userData.id,
        amount: amount,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      setUserData({
        ...userData,
        ref: {
          ...userData.ref,
          rub: method === 'giveRub' ? userData.ref.rub + amount : userData.ref.rub - amount,
        },
      });
      setError(null);
    } catch (error) {
      setError('Ошибка при обновлении баланса');
    }
  };

  const handleDeactivatePartner = async () => {
    try {
      await axios.post('https://pay.kraca.ru/apivk', {
        method: 'deactivatePartner',
        id: userData.id,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      setUserData({ ...userData, partner: false });
      setError(null);
    } catch (error) {
      setError('Ошибка при деактивации партнера');
    }
  };

  const fetchActivePartners = async () => {
    try {
      const response = await axios.post('https://pay.kraca.ru/apivk', {
        method: 'activePartners',
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      setActivePartners(response.data.partners);
    } catch (error) {
      setError('Ошибка при получении списка активных партнеров');
    }
  };

  const handlePartnerClick = (partnerId) => {
    setSearchTerm(partnerId);
    handleSearch(partnerId);        
  };

  return (
    <Container className="mt-4">
      <div className="back-button-container">
        <Button variant="secondary" onClick={() => navigate('/admin2')}>Вернуться назад</Button>
      </div>
      <div className="d-flex flex-column align-items-center">
        <h1>Поиск партнера</h1>
        <Form onSubmit={handleSearch}>
          <Form.Group controlId="search">
            <Form.Label>Введите ID пользователя или username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Введите id или username"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-2" disabled={loading}>
            {loading ? 'Поиск...' : 'Поиск'}
          </Button>
        </Form>
        {error && <p className="text-danger mt-3">{error}</p>}
        {userData && (
          <Card className="mt-4 user-info-card">
            <Card.Body>
              <Card.Title className="text-center">Информация о пользователе</Card.Title>
              {userData.partner ? (
                <div>
                  <div className="api-info">
                    <p>ID: {userData.id}</p>
                    <p>Баланс: {userData.ref.rub}</p>
                    <p>Пригласил: {userData.ref.count}</p>
                    <p>Активные подписки: {userData.activePremium}</p>
                  </div>
                  <Form className="mt-3">
                    <Form.Group controlId="amount">
                      <Form.Label>Сумма</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Введите сумму"
                        onChange={(e) => setAmount(parseFloat(e.target.value))}
                      />
                    </Form.Group>
                    <Row className="justify-content-center mb-2">
                      <Col xs="auto" className="mb-2">
                        <Button variant="success" onClick={() => handleUpdateBalance(amount, 'giveRub')}>Выдать RUB</Button>
                      </Col>
                      <Col xs="auto" className="mb-2">
                        <Button variant="danger" onClick={() => handleUpdateBalance(amount, 'takeRub')}>Забрать RUB</Button>
                      </Col>
                    </Row>
                    <Row className="justify-content-center mb-2">
                      <Col xs="auto" className="mb-2">
                        <Button variant="secondary" onClick={handleDeactivatePartner}>Деактивировать</Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              ) : (
                <div>
                  <p>Пользователь не зарегистрирован как партнер</p>
                  <Button variant="primary" onClick={handleRegisterPartner}>Зарегистрировать</Button>
                </div>
              )}
            </Card.Body>
          </Card>
        )}
        <Button variant="info" className="mt-3" onClick={fetchActivePartners}>Активные партнеры</Button>
        {activePartners.length > 0 && (
          <ListGroup className="mt-4 wide-list-group">
            {activePartners.map((partner) => (
              <ListGroup.Item key={partner.id} className="d-flex justify-content-between align-items-center">
                {partner.username || partner.id}
                <Button variant="primary" onClick={() => handlePartnerClick(partner.id)}>Просмотреть</Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
    </Container>
  );
};

export default Partner2;
